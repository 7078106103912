import NONINTRUSIVE_INSTALLATION from "../../assets/nonIntrusive.svg";
import PREDICTIVE_ANALYSIS from "../../assets/predictiveAnalysis.svg";
import REAL_TIME_ALERTS from "../../assets/realTimeAlerts.svg";
import AUTOMATED_REPORTING from "../../assets/automatedReporting.svg";
import DATA_INTEGRATION from "../../assets/dataIntegration.svg";
import BENCHMARKING from "../../assets/benchmarking.svg";
import OPTIMIZATION from "../../assets/optimization.svg";
import COLLABORATION from "../../assets/collaboration.svg";
import EQUIPMENT_HEALTH from "../../assets/equipmentHealth.svg";

function Features() {
  const features_list = [
    {
      icon: NONINTRUSIVE_INSTALLATION,
      title: "Non-Intrusive Installation",
      desc: "Our implementation method ensures existing data collection is not interrupted during installation. Our flexible data collection solutions can leverage existing sensors or we can install new state-of-the-art sensors.",
    },
    {
      icon: PREDICTIVE_ANALYSIS,
      title: "Predictive Analysis",
      desc: "Know how utility cost increases will impact your bottom line giving you time to plan and react. Optimize your operations with insights on seasonal spikes or dips in the utilization of utilities.",
    },
    {
      icon: REAL_TIME_ALERTS,
      title: "Real-Time Alerts",
      desc: "Stay in-tune with plant operations anywhere, anytime. SMS or Email-based alarms can be set up by users to receive immediate alerts on significant threshold deviations.",
    },
    {
      icon: AUTOMATED_REPORTING,
      title: "Automated Reporting",
      desc: "Save time by using our out-of-the-box reports. Easily customize your reports to suit your needs using the user-level interface. Quickly and easily export your reports to excel and share with your peers and management.",
    },
    {
      icon: DATA_INTEGRATION,
      title: "Data Integration",
      desc: "Enrich your business performance reports by combining plant data with your business data.",
    },
    {
      icon: BENCHMARKING,
      title: "Benchmarking",
      desc: "Benchmark your current operational expenses and highlight areas for improvement. Use benchmarks to identify spikes in power or water usage due to pilferage.",
    },
    {
      icon: OPTIMIZATION,
      title: "Optimization",
      desc: "Know how your teams and shifts compare in the use of plant resources helping you to optimize your processes and measure process compliance.",
    },
    {
      icon: COLLABORATION,
      title: "Collaboration",
      desc: "Allow users to share data and insights with team members, stakeholders, and partners, to foster collaboration and drive better decision-making.",
    },
    {
      icon: EQUIPMENT_HEALTH,
      title: "Equipment Health",
      desc: "Monitor the performance of your plant equipment and replace before it causes operational impact.",
    },
  ];

  return (
    <section class="bg-gradient-to-b from-purple to-lightBlue w-full flex flex-col justify-center items-center pb-16">
      <div class="w-[70%] text-white xs:text-4xl lg:text-5xl font-medium text-center mt-16">
        Features
      </div>
      <div class="lg:w-[90%] xl:w-[70%] bg-white rounded-3xl md:p-5 lg:p-10 m-10 grid xs:grid-cols-2 md:grid-cols-3  shadow-xl shadow-black ">
        {features_list.map((feature, index) => (
          <div
            key={index}
            class={`flex flex-col items-center xs:p-5 md:p-10 group ${
              (index + 1) % 3 !== 0 ? "md:border-r-2 md:border-gray" : "" // Add right border if not in the last column (for 3x3 grid - md and above)
            } ${
              index < features_list.length - 3
                ? "md:border-b-2 md:border-gray"
                : "" // Add bottom border if not in the last row (for 3x3 grid - md and above)
            } 
            
            ${
              (index + 1) % 2 !== 0
                ? "xs:border-r-2 xs:border-gray md:border-r-0 "
                : "" // Add right border if not in the last column (for 2x2 grid - xs and sm)
            } ${
              index < features_list.length - 2
                ? "xs:border-b-2 xs:border-gray "
                : "" // Add bottom border if not in the last row (for 2x2 grid - xs and sm)
            }  ${
              index === 7 ? "xs:border-b-2 xs:border-gray md:border-b-0" : "" // Add bottom border if not in the last row (for 2x2 grid - xs and sm)
            }  ${
              index === 6 ? "md:border-b-0" : "" // Add bottom border if not in the last row (for 2x2 grid - xs and sm)
            } 
            
            
            `}
          >
            <img
              src={feature.icon}
              alt={feature.title}
              class="xs:w-1/3 md:w-2/5 mb-4 transition ease-in-out delay-150 group-hover:-translate-y-1 group-hover:scale-110"
            />
            <div class="flex flex-col justify-center text-center gap-4 ">
              <h3 class=" xs:text-lg sm:text-xl lg:text-lg font-medium h-[100%]">
                {feature.title}
              </h3>
              <h5 class=" xs:text-xs sm:text-sm md:text-xs lg:text-xs text-center ">
                {feature.desc}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Features;
