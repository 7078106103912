// import HAND_WITH_PHONE from "../../assets/hand_with_phone.png";
// import { ArrowRightIcon } from "@heroicons/react/24/solid";
// import Card from "../OurSystem_card";
// import DEVICES from "../../assets/devices_toolbox.svg";
import OUR_SYSTEM_DEVICES from "../../assets/ourSystemDevices.gif";

function OurSystem() {
  return (
    <section class=" w-[80%] self-center ">
      <div class=" flex flex-col justify-betweeen items-center text-center flex-wrap">
        <h1 class="xs:text-3xl md:text-4xl lg:text-5xl font-medium z-10 xs:-mb-2 md:-mb-8">
          Our System
        </h1>
        <img
          src={OUR_SYSTEM_DEVICES}
          alt="Our System Devices"
          class="xs:w-full md:w-[85%] xl:w-[70%] z-0"
        />
        <p class=" xl:w-[90%] text-center z-10 xs:-mt-2 md:-mt-8">
          Access all your monitored data in one place, along with customizable
          analytics and essential features like costing, time-based alarms, OPEX
          analysis, and digital twins, enabling effective profitability
          optimization. Our solution is manufacturer-agnostic, offering a
          cost-effective alternative to vendor-specific data logging platforms
          which are limited in functionality, inconvenient, and expensive
        </p>
      </div>
    </section>
  );
}
export default OurSystem;

// <section class=" mb-10 flex flex-col items-center justify-center xs:mx-10 md:mx-12 lg:mx-12 xl:mx-0">
//   <div class="text-center mb-16">
//     <h1 class="text-5xl font-bold text-black mb-5">Our System</h1>
//     <div class="border-b-2 border-purple w-1/6 mx-auto mb-5"></div>
//     <p class=" md:w-[80%] lg:w-[87%] xl:w-[75%] xxl:w-[42%] mx-auto flex justify-center sm:text-sm md:text-base">
//       Our solution is manufacturer-agnostic, offering a cost-effective
//       alternative to subscription-based platforms. Access all your monitored
//       data in one place, along with customizable analytics and essential
//       features like costing, time-based alarms, OPEX analysis, and digital
//       twins, enabling effective profitability optimization.
//     </p>
//   </div>
//   <section>
//     <div class="max-w-6xl overflow-visible shadow-lg bg-gradient-to-r from-blue to-lightBlue flex rounded-tr-3xl rounded-tl-3xl rounded-br-3xl sm:flex xs:flex-col-reverse md:flex-row">
//       <div class="">
//         <img
//           src={HAND_WITH_PHONE}
//           alt="Hand holding phone with Irvine app on screen"
//           class="md:mt-28 xmd:mt-18 lg:-mt-7 xl:-mt-16 w-full xs:w-4/5 sm:w-3/5 md:w-full xl:w-5/6 "
//         />
//       </div>
//       <div class="md:mr-16 xs:mx-6 py-4 lg:p-4 xl:p-5 xs:text-center md:text-left  flex xs:flex-col xs:justify-center md:items-start">
//         <div class="font-bold xs:text-2xl  lg:text-3xl text-white font-bold mb-5">
//           Software-as-a-Service (SaaS) Solution
//         </div>
//         <div class="text-white xs:text-sm md:text-sm lg:text-base">
//           <p class="mb-2">
//             Irvine offers an affordable and easy-to-use way to track your
//             plant's performance live.
//           </p>
//           <p class="">
//             The Irvine Analytics system is a state-of-the-art data
//             collection and turn-key package, utilizing the SaaS commercial
//             model and avoiding expensive licensing and software costs.
//             Irvine will initially audit the client's requirements, then
//             prepare a package including site equipment and tailor-made
//             dashboard and analytics.
//           </p>
//         </div>

//         <button class="transition ease-in-out delay-150 hover:-translate-y-1 xs:hover:scale-100 md:hover:scale-110 bg-white font-bold text-black mix-blend-screen text-1xl px-4 py-1 rounded-full flex items-center justify-center mt-7 xs:mb-10 sm:mb-10 md:mb-0">
//           Get A Demo
//           <ArrowRightIcon class="ml-2 h-8 w-6 font-bold" />
//         </button>
//       </div>
//     </div>
//   </section>
//   <Card />
// </section>
