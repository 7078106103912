import ICON1 from "../assets/our_system_icon_1.svg";
import ICON2 from "../assets/our_system_icon_2.svg";
import ICON3 from "../assets/our_system_icon_3.svg";
import ICON4 from "../assets/our_system_icon_4.svg";

const cardsData = [
  {
    title: "Real-Time and Remote Data Monitoring",
    icon: ICON1,
    desc: "Irvine's automatic report generation helps companies monitor and reduce operational costs with real-time data.  Cloud integration allows global access via cellular and WiFi. Irvine's customizable solutions, add-on modules, and dynamic interface offer tailored, real-time data monitoring with customizable charts.",
  },
  {
    title: "Predictive Analytics",
    icon: ICON2,
    desc: "Generate predictions for optimal plant or equipment maintenance schedules with Irvine's machine learning-based predictive analysis. Irvine provides real-time analytics to predict and compare plant performance, highlighting equipment issues and inefficiencies in operational processes.",
  },
  {
    title: "Turn-Key Data Acquisition",
    icon: ICON3,
    desc: "Data from existing and new sensors are processed and transmitted to our cloud-based database utilizing secure centralized data transmission and warehouse.",
  },
  {
    title: "Non-intrusive data capture",
    icon: ICON4,
    desc: "Our on-site solution is self-contained and deploys uninterrupted power supply, power conditioning, network security controls, and backup data and network connections.",
  },
];

function ToolBox_Cards() {
  return (
    <section className="xs:w-full grid xs:grid-cols-1 md:grid-cols-2 xs:gap-y-8 md:gap-y-8 md:gap-8 xl:gap-y-10 xl:gap-10 xs:mt-12 md:mt-12 lg:mt-14">
      {cardsData.map((info, index) => (
        <div
          key={index}
          className="group flex flex-col items-center xs:shadow-xl md:shadow-xl gap-4 bg-gray rounded-2xl p-10"
        >
          <img
            src={info.icon}
            alt={info.title}
            className="w-1/2 transition ease-in-out delay-150 group-hover:-translate-y-1 group-hover:scale-105"
          />
          <div className="flex flex-col justify-center text-center gap-4">
            <h3 className="xs:text-lg sm:text-xl lg:text-2xl font-medium h-[100%]">
              {info.title}
            </h3>
            <h5 className="xs:text-xs sm:text-sm md:text-xs lg:text-sm text-center">
              {info.desc}
            </h5>
          </div>
        </div>
      ))}
    </section>
  );
}

export default ToolBox_Cards;
