import IAS_LOGO from "../assets/ias_logo_gray.svg";
import FACEBOOK_LOGO from "../assets/facebook_logo_white.svg";
import TWITTER_LOGO from "../assets/twitter_logo_white.svg";
import LINKEDIN_LOGO from "../assets/linkedIn_logo_white.svg";
import EMAIL_LOGO from "../assets/email_logo_white.svg";
import { Link } from "react-router-dom";

function Footer() {
  const navItems = [
    { name: "About", link: "/AboutUs" },
    { name: "Tool Box and Services", link: "/ProductsAndServices" },
    { name: "Case Studies", link: "/CaseStudies" },
    { name: "Our Team", link: "/OurTeam" },
    { name: "Contact Us", link: "/ContactUs" },
    { name: "Terms & Conditions ", link: "#" },
    { name: "Privacy Policy", link: "#" },
  ];

  return (
    <footer class="bg-black flex xs:flex-col md:flex-row justify-around py-10 xs:gap-12 md:gap-16 lg:gap-16 xl:gap-32 xs:px-12 md:px-14 lg:px-24">
      <div>
        <div class="mb-7">
          <img
            src={IAS_LOGO}
            alt="IAS Logo"
            class="xs:w-3/6  sm:w-2/6 md:w-4/6 lg:w-3/5 xl:w-1/2"
          />
          <div class="text-white xs:text-sm lg:text-base">
            Optimize. Automate. Simplify.
          </div>
        </div>
        <div class="flex-col">
          <div class="flex mb-2 ">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FACEBOOK_LOGO} alt="Facebook Logo" class="w-2/3" />
            </a>
            <a
              href="https://twitter.com/irvineas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TWITTER_LOGO} alt="Twitter Logo" class="w-2/3" />
            </a>
            <a
              href="https://www.linkedin.com/company/irvine-analytics-solutions-inc/mycompany/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LINKEDIN_LOGO} alt="LinkedIn Logo" class="w-2/3" />
            </a>
            <a href="mailto:enquiries@irvineas.com">
              <img src={EMAIL_LOGO} alt="Email Logo" class="w-2/3" />
            </a>
          </div>
          <div class="text-white text-xs">
            Copyright © 2024. All rights reserved.
          </div>
        </div>
      </div>

      <div class="md:w-1/2 xmd:w-1/3 lg:w-1/4 xl:w-1/5">
        <div class="text-white mb-5 text-xl font-semibold mt-3">
          QUICK LINKS
        </div>
        <div class="text-white flex flex-col xs:text-sm lg:text-base">
          {navItems.map((item, index) => (
            <Link to={item.link}>
              <button class="hover:font-medium">{item.name}</button>
            </Link>
          ))}
        </div>
      </div>

      <div class="xs:2/3 md:w-1/3 mt-3 xs:text-sm lg:text-base">
        <div class="text-white text-xl font-semibold mb-5">CONTACT</div>
        <div class="text-white mb-3">
          68 Circular Road, #02-01, Singapore 049422
        </div>
        <div class="text-white">
          Unit 214-215, 2/f Commercenter Alabang, Commerce Avenue cor. East Asia
          Drive, Filinvest Corporate City, Muntinlupa, Metro Manila, Philippines
        </div>
      </div>
    </footer>
  );
}

export default Footer;
