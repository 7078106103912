import React from "react";

const CircleWithTriangle = ({ imageUrl, bgColor }) => {
  // Function to check if the screen is extra small

  const circleStyle = {
    width: "85px", // Adjusted size for xs screens
    height: "85px",
    borderRadius: "50%", // Makes the div a circle
    backgroundColor: bgColor, // Circle color
    position: "relative", // Needed for absolute positioning of the triangle
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // Styles for the triangle, dynamically adjusted for xs screens
  const triangleStyle = {
    width: "0",
    height: "0",
    borderTop: "15px solid transparent",
    borderBottom: "15px solid transparent",

    borderLeft: `18px solid ${bgColor}`,
    position: "absolute",
    right: "-15px", // Adjusted position for xs screens
  };

  const imageStyle = {
    width: "85%", // Slightly smaller than the circle to fit inside
    height: "85%",
    borderRadius: "50%", // Makes the image a circle
    objectFit: "cover", // Ensures the image covers the space without distortion
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={circleStyle}>
        <img src={imageUrl} alt="Inner Circle" style={imageStyle} />
        <div style={triangleStyle}></div>
      </div>
    </div>
  );
};

export default CircleWithTriangle;

// // Function to check if the screen is extra small
// const isExtraSmallScreen = () => window.innerWidth < 1100;

// const circleStyle = {
//   width: isExtraSmallScreen() ? "75px" : "95px", // Adjusted size for xs screens
//   height: isExtraSmallScreen() ? "75px" : "95px",
//   borderRadius: "50%", // Makes the div a circle
//   backgroundColor: bgColor, // Circle color
//   position: "relative", // Needed for absolute positioning of the triangle
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

// // Styles for the triangle, dynamically adjusted for xs screens
// const triangleStyle = {
//   width: "0",
//   height: "0",
//   borderTop: isExtraSmallScreen()
//     ? "15px solid transparent"
//     : "20px solid transparent", // Adjusted size for xs screens
//   borderBottom: isExtraSmallScreen()
//     ? "15px solid transparent"
//     : "20px solid transparent",
//   borderLeft: isExtraSmallScreen()
//     ? `18px solid ${bgColor}`
//     : `24px solid ${bgColor}`, // Adjusted color for xs screens
//   position: "absolute",
//   right: isExtraSmallScreen() ? "-15px" : "-20px", // Adjusted position for xs screens
// };

// const imageStyle = {
//   width: "85%", // Slightly smaller than the circle to fit inside
//   height: "85%",
//   borderRadius: "50%", // Makes the image a circle
//   objectFit: "cover", // Ensures the image covers the space without distortion
// };

// return (
//   <div style={{ display: "flex", alignItems: "center" }}>
//     <div style={circleStyle}>
//       <img src={imageUrl} alt="Inner Circle" style={imageStyle} />
//       <div style={triangleStyle}></div>
//     </div>
//   </div>
// );
