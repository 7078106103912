// import FEATURE_BG from "../assets/header_bg.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
// import FEATURE_DEVICES from "../../assets/feature_devices.png";
import { Link } from "react-router-dom";

import ICON1 from "../../assets/card_hero_1_icon.png";
import ICON2 from "../../assets/card_hero_2_icon.png";
import ICON3 from "../../assets/card_hero_3_icon.png";
import ICON4 from "../../assets/card_hero_4_icon.png";
import ICON5 from "../../assets/card_hero_5_icon.png";
import IMAGE1 from "../../assets/hero_image1.svg";
import IMAGE2 from "../../assets/hero_image2.svg";
import IMAGE3 from "../../assets/hero_image3.svg";
import HeroCard from "../Hero_card";

function Hero() {
  const iconCardData = [
    { icon: ICON1, title: "Cost Optimization", scrollToId: "textDesc-0" },
    {
      icon: ICON2,
      title: "Proactive Operations Monitoring",
      scrollToId: "textDesc-1",
    },
    {
      icon: ICON3,
      title: "Real-time Visualization and Auto Reporting",
      scrollToId: "textDesc-2",
    },
    { icon: ICON4, title: "Easy Secure Access", scrollToId: "textDesc-3" },
    {
      icon: ICON5,
      title: "Predictive Maintenance and Cost to Operate",
      scrollToId: "textDesc-4",
    },
  ];
  return (
    <section class="  xs:h-[120vh] md:h-[110vh] bg-gradient-to-br from-purple from-10% to-lightBlue to-100% flex justify-center items-center xs:mb-32 md-0">
      <div class="md:w-[90%] xl:w-[80%] flex flex-col justify-center items-center mb-20">
        <div class="flex xs:flex-col md:flex-row justify-center items-center xs:gap-10 md:gap-0">
          <div class=" xs:w-3/4 md:w-1/2 flex flex-col xs:items-center md:items-start gap-8 text-white  xs:text-center md:text-left">
            <h1 class="xs:text-4xl  lg:text-5xl xl:text-6xl font-medium ">
              Manufacturing & ​Commercial ​Analytics Toolbox
            </h1>
            <p class="xs:text-sm md:text-base lg:text-lg xl:text-xl w-5/6">
              Our solution is manufacturer-agnostic, ​offering a cost-effective
              alternative to ​subscription-based platforms.
            </p>
            <Link to="/ContactUs">
              <button class="w-1/2 xs:text-sm sm:text-lg xl:text-xl px-6 py-3  bg-lightBlack font-medium rounded-lg flex flex-row justify-center items-center hover:text-green w-full">
                Get A Demo
                <ArrowRightIcon class="h-6 w-6 ml-2" />
              </button>
            </Link>
          </div>
          <div class="md:w-1/2 flex flex-row items-center justify-center xs:gap-4 md:gap-0 ">
            <div class="w-1/2 flex justify-end justify-end">
              <img
                src={IMAGE1}
                alt="Hero 1"
                class="rounded-lg xs:w-3/5 md:w-5/6"
              />
            </div>
            <div class="w-1/2 flex flex-col xs:justify-center md:items-end gap-5">
              <img
                src={IMAGE2}
                alt="Hero 1"
                class="rounded-lg xs:w-3/5 md:w-5/6"
              />
              <img
                src={IMAGE3}
                alt="Hero 1"
                class="rounded-lg xs:w-3/5 md:w-5/6"
              />
            </div>
          </div>
        </div>
        <div class="absolute w-[80%] lg:w-[70%] bg-white rounded-lg flex flex-row justify-around items-center  xs:mt-[130vh] md:mt-[120vh] shadow-2xl md:flex md:flex-row md:justify-around md:items-center grid grid-cols-3 gap-y-3 gap-2 md:grid-cols-none p-10 ">
          {iconCardData.map(({ icon, title, scrollToId }, index) => (
            <HeroCard
              key={index}
              icon={icon}
              title={title}
              scrollToId={scrollToId}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Hero;
