import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import {
  PlusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";

const TextDescIcons = () => {
  const [open, setOpen] = useState(null);

  const handleOpen = (index) => {
    setOpen(open === index ? null : index);
  };

  const textDesc = [
    {
      id: "cost-optimization",
      title: "COST OPTIMIZATION",
      list: [
        "Reduce the Time and Cost to Produce the Product",
        "Save Money on Utilities, Chemicals Consumption, And Maintenance Through Data Analysis",
        "Minimise Unplanned Use of Resources",
      ],
      bgColor: "#2a31c3",
    },
    {
      id: "proactive-operations-monitoring",
      title: "PROACTIVE OPERATIONS MONITORING",
      list: [
        "Proactively Identify/Detect Performance Issues In Your Processes/Systems",
        "Receive Notifications or Alerts Potential Issues",
      ],
      bgColor: "#607cf4",
    },
    {
      id: "real-time-visualization",
      title: "REAL-TIME VISUALIZATION AND AUTO-REPORTING",
      list: [
        "Realtime Views On Operational Health/ Real-Time Data Monitoring",
        "Generate Detailed Reports For Easy Data Analysis",
        "Automatic Report Generation Of Your Plant/Operations/Business",
        "Customizable Solutions Tailored To Client Requirements",
        "Add On Modules",
        "Customizable Charts",
        "Dynamic Interface",
      ],
      bgColor: "#7c1cda",
    },
    {
      id: "easy-secure-access",
      title: "EASY SECURE ACCESS",
      list: [
        "Remote Sensor Management with AI insights",
        "Accessibility (See Data Any Time from Any Device)",
        "Fully Encrypted Data",
      ],
      bgColor: "#488aff",
    },
    {
      id: "predictive-maintenance",
      title: "PREDICTIVE MAINTENANCE AND COST TO OPERATE",
      list: [
        "Predict Failures in Equipment or System",
        "Machine Learning-Based Predictive Analysis for Equipment Or System Failures",
        "Identify Inefficiencies in Operational Processes",
        "Control Options",
        "Minimize Unexpected Failures Based on Real-Time Monitoring",
      ],
      bgColor: "#2d2c8a",
    },
  ];

  return (
    <section className="flex justify-center items-center -mt-10">
      <div className="md:w-[90%] xl:w-[80%]">
        <div className="flex-col justify-center items-center text-white">
          {textDesc.map((desc, index) => (
            <Accordion
              key={index}
              open={open === index}
              onClick={() => handleOpen(index)}
              className=""
              style={{ backgroundColor: desc.bgColor }}
            >
              <AccordionHeader
                className="font-medium xs:text-base lg:text-lg p-5"
                id={`textDesc-${index}`}
                style={{ cursor: "pointer" }}
              >
                <div className="flex justify-between items-center w-full">
                  <span>{desc.title}</span>
                  {open === index ? (
                    <ChevronUpIcon className="h-6 w-6 " />
                  ) : (
                    <ChevronDownIcon className="h-6 w-6" />
                  )}
                </div>
              </AccordionHeader>
              <div
                style={{
                  maxHeight: open === index ? "500px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.3s ease",
                }}
              >
                <AccordionBody>
                  <div className="flex xs:text-sm lg:text-base flex-col">
                    <ul>
                      {desc.list.map((item, idx) => (
                        <div key={idx} className="flex m-3 gap-2 pl-2">
                          <PlusIcon className="h-5 w-5 self-center" />
                          <li className="text-pretty w-4/5">{item}</li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </AccordionBody>
              </div>
            </Accordion>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TextDescIcons;
