// import HERO_OURTEAM from "../../assets/ourTeam_Bg.png";

function Hero_OurTeam() {
  return (
    <section class="bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center h-[50vh] w-screen flex justify-center items-center">
      <div class="flex flex-col justify-center items-center ">
        <div class="xs:text-5xl lg:text-6xl text-white font-medium text-center">
          Meet Our Team
        </div>

        <div class=" absolute -mb-[50vh] flex justify-center items-center xs:w-[80%]  md:w-3/5 lg:w-3/5 xl:w-1/2 rounded-3xl bg-white  xs:p-6 md:p-8 lg:p-12  xs:gap-2 md:gap-4 xl:gap-6 shadow-2xl text-center ">
          <h1 class="text-purple xs:text-3xl sm:text-4xl md:text-4xl lg:text-6xl font-bold">
            30+
          </h1>
          <div class="flex flex-col">
            <h3 class=" xs:text-sm sm:text-xl md:text-xl lg:text-3xl">
              YEARS OF COMBINED
            </h3>
            <h3 class=" xs:text-sm sm:text-xl  md:text-xl lg:text-3xl">
              INUSTRY EXPERIENCE
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero_OurTeam;
