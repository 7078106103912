import INTUITIVE_DASHBOARD from "../../assets/intuitiveDashboard.svg";
import TRAFFIC_LIGHT_SYSTEM from "../../assets/trafficLightSystem.svg";
import DATA_VISUALIZATION from "../../assets/dataVisualization.svg";
import LIVE_GRAPHS from "../../assets/liveGraphs.svg";
import EXPORT_TO_CSV from "../../assets/exportToCSV.svg";

import React, { useState } from "react";

function SoftwareOverview() {
  const overview = [
    {
      option: "Intuitive Dashboard",
      img: INTUITIVE_DASHBOARD,
      desc: "A simple Dashboard gives the operators feedback on overall and individual plant performance.",
    },
    {
      option: "Traffic Light System",
      img: TRAFFIC_LIGHT_SYSTEM,
      desc: "Equipment conditions at a glance.",
    },
    {
      option: "Data Visualization",
      img: DATA_VISUALIZATION,
      desc: "Immediate insights that tell a story.",
    },
    {
      option: "Live Graphs",
      img: LIVE_GRAPHS,
      desc: "Live historical graphs.",
    },
    {
      option: "Export to CSV",
      img: EXPORT_TO_CSV,
      desc: "Directly export data to .csv files.",
    },
  ];

  // Set the initial value of selectedOption to the first item in the overview array
  const [selectedOption, setSelectedOption] = useState(overview[0].option);

  // Find the selected option object
  const selectedOptionObject = overview.find(
    (item) => item.option === selectedOption
  );

  return (
    <section class="xs:w-[85%] md:w-[90%] xl:w-[70%] flex flex-col justify-center items-center ">
      <div class="xs:text-4xl lg:text-5xl font-medium text-center mb-14">
        Sustainability and Resilience
      </div>
      {/* <div class="border-b-2 border-purple w-[200px] mx-auto mt-5 mb-14"></div> */}
      <div class="flex xs:flex-col md:flex-row">
        <div class="md:w-[40%] xs:flex xs:flex-wrap xs:gap-1 sm:gap-2 md:gap-0 md:flex md:flex-col">
          {overview.map((item, index) => (
            <div
              class={`xs:text-sm md:text-lg lg:text-xl md:p-6 rounded-l-3xl ${
                selectedOption === overview[index].option
                  ? "md:bg-gray"
                  : "md:bg-white"
              }`}
            >
              <button
                key={index}
                onClick={() => setSelectedOption(item.option)}
                class={`${
                  selectedOption === item.option ? "bg-purple" : "bg-black"
                } text-white p-2 rounded-full font-medium w-full`}
              >
                {item.option}
              </button>
            </div>
          ))}
        </div>
        <div className="md:w-[60%] bg-gray xs:p-6 md:p-10 xs:rounded-3xl md:rounded-none md:rounded-r-3xl xs:mt-4 md:mt-0 ">
          {selectedOptionObject && (
            <div class="flex flex-col justify-center items-center gap-5 text-center ">
              <img
                src={selectedOptionObject.img}
                alt={selectedOptionObject.option}
                class="object-fill h-auto rounded-3xl border-purple border-2"
              />
              <p class="md:h-10  xs:text-sm sm:text-base ">
                {selectedOptionObject.desc}
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default SoftwareOverview;

// <div class="w-[40%] flex flex-col gap-4 text-center text-2xl">
//   <div class="bg-gray p-5 rounded-l-3xl ">
//     <div class="rounded-full bg-purple p-3 text-white font-semibold">
//       Intuitive Dashboard
//     </div>
//   </div>
//   <div>rjgfgfjgn</div>
// </div>
// <div class="w-[60%] bg-gray p-14 flex flex-col justify-center items-center rounded-3xl rounded-tl-none">
//   <img
//     src={SOFTWARE_OVERVIEW}
//     alt="Software Overview"
//     class="rounded-2xl border-purple border-2"
//   />
//   <p class="text-center mt-6">
//     A simple Dashboard gives the operators feedback on overall and
//     individual plant performance.
//   </p>
// </div>
