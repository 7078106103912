import { MapPinIcon } from "@heroicons/react/24/solid";

function WhereToFindUs() {
  return (
    <section class=" flex justify-center  bg-paleBlue w-full">
      <div class="xs:w-[80%] md:w-[85%] xl:w-[80%] flex flex-col items-center justify-center py-20 gap-20">
        <h1 class="text-center xs:text-4xl lg:text-5xl font-medium ">
          Where to Find Us?
        </h1>

        <div class="bg-white rounded-lg shadow-xl flex md:flex-row xs:flex-col justify-between w-full md:mt-20 md:mb-20">
          <div class="md:w-1/2 flex flex-col justify-start p-10">
            <h1 class="xs:text-lg sm:text-xl md:text-xl lg:text-2xl font-medium ">
              IRVINE ENVIRONMENTAL PTE. LTD.
            </h1>
            <div class="flex flex-row gap-3 mt-3">
              <MapPinIcon class="w-8 h-8" />
              <p class="self-center w-[90%]">
                68 Circular Road, #02-01, Singapore 049422
              </p>
            </div>
          </div>

          <div class="md:w-1/2 flex justify-end">
            <iframe
              title="Singapore Office Address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.812678070124!2d103.8465673744722!3d1.2864471617774056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190bb5febd0b%3A0x3999e3ace9eb9af!2s68%20Circular%20Rd%2C%20Unit%2002-01%2C%20Singapore%20049422!5e0!3m2!1sen!2sph!4v1721374021795!5m2!1sen!2sph"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              class=" right-0 top-0 xs:rounded-b-lg md:rounded-lg w-full h-[350px] md:-mt-20 md:-mb-20"
            ></iframe>
          </div>
        </div>

        <div class="bg-white rounded-lg shadow-xl flex md:flex-row xs:flex-col justify-between w-full md:mt-20 md:mb-20">
          <div class="md:w-1/2 flex flex-col justify-between p-10">
            <h1 class="xs:text-lg sm:text-xl md:text-xl lg:text-2xl font-medium ">
              IRVINE ANALYTICS SOLUTIONS
            </h1>
            <div class="flex flex-row gap-3 mt-3">
              <MapPinIcon class="w-8 h-8 " />
              <p class="self-center w-[90%] ">
                Unit 214-215, 2/f Commercenter Alabang, Commerce Avenue cor.
                East Asia Drive, Filinvest Corporate City, Muntinlupa, Metro
                Manila, Philippines
              </p>
            </div>
          </div>

          <div class="md:w-1/2 flex justify-end">
            <iframe
              title="Philippines Office Address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3864.0084017586964!2d121.02279537457!3d14.426679181312153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d1ce490a4ac3%3A0xc8e733a24739e87!2sRichville%20Corporate%20Tower!5e0!3m2!1sen!2sph!4v1721377234415!5m2!1sen!2sph"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              class=" right-0 top-0 xs:rounded-b-lg md:rounded-lg w-full h-[350px] md:-mt-20 md:-mb-20"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhereToFindUs;
