import React from "react";

import NavBar from "../components/NavBar";
import Hero from "../components/aboutUs/Hero_AboutUs";
import OurVision from "../components/aboutUs/OurVision_AboutUs";
import SustainabilityAboutUs from "../components/aboutUs/Sustainability_AboutUs";
import GetInTouchWithUs from "../components/GetInTouchWithUs";
import Footer from "../components/Footer";
import useScrollToTop from "../hooks/useScrollToTop";

function AboutUs() {
  useScrollToTop();
  return (
    <div class="">
      <div class="flex flex-col justify-center items-center gap-20">
        <div class=""></div>
        <NavBar />
        <Hero />
        <OurVision />
        <SustainabilityAboutUs />
      </div>
      <GetInTouchWithUs />

      <Footer />
    </div>
  );
}

export default AboutUs;
