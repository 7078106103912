import UNDP_GOALS_IMAGE from "../assets/undp_goals.svg";

function UNDPGoals() {
  return (
    <div class="flex xs:flex-col md:flex-row justify-center items-center">
      <img
        src={UNDP_GOALS_IMAGE}
        alt="UNDP Logo"
        class="xs:w-5/6 md:w-1/2 lg:w-2/3 xl:w-full"
      />
      <p class="xs:text-base md:text-base lg:text-xl  xs:p-6 lg:p-12  xs:text-center md:text-justify">
        Irvine supports companies embracing ESG practices and UN's SDGs. Our
        software aims to improve your ESG score and other business metrics.
      </p>
    </div>
  );
}

export default UNDPGoals;
