const HeroCard = ({ icon, title, scrollToId }) => {
  const handleClick = () => {
    const element = document.getElementById(scrollToId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <button
      onClick={handleClick}
      class="flex flex-col text-center justify-center items-center gap-3 group"
    >
      <img
        src={icon}
        alt={title}
        className="object-cover xs:w-1/2 md:w-1/2 lg:w-1/2 xl:1/3 transition ease-in-out delay-150 group-hover:-translate-y-1 group-hover:scale-110"
      />
      <p className="xs:text-xs md:text-sm font-medium xs:w-24 sm:w-auto group-hover:text-purple">
        {title}
      </p>
    </button>
  );
};

export default HeroCard;
