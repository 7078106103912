import Carousel from "../FoodBeverageManufacturing_carousel";

function FoodBeverageManufacturing() {
  return (
    <section>
      <div class="text-center flex flex-col justify-center items-center gap-3">
        <div class=" font-medium w-42 text-balance mb-5 lg:mx-4 xxl:mx-[15%] flex flex-col gap-3">
          <h1 class="xs:text-3xl md:text-5xl">
            Designed For Both Manufacturing Plants and Commercial Applications
          </h1>
          <h4 class="xs:text-xl md:text-2xl">
            (Food, Beverage, Dairy, Meat-Processing, Water and Wastewater,
            Manufacturing)
          </h4>
        </div>

        {/* <div class="border-b-2 border-purple w-1/6 mx-auto mb-5"></div> */}
        <p class="xs:text-sm md:text-lg w-[60%] mx-auto flex justify-center mb-12">
          Irvine is an essential solution to help Operators and Managers take
          control of their plants, make data-driven decisions, and simplify
          operations.
        </p>
      </div>
      <div class="flex justify-center">
        <Carousel />
      </div>
    </section>
  );
}

export default FoodBeverageManufacturing;
