import IMAGE1 from "../assets/HowCanIrvineHelp_image_1.svg";
import IMAGE2 from "../assets/HowCanIrvineHelp_image_2.svg";
import IMAGE3 from "../assets/HowCanIrvineHelp_image_3.svg";
import IMAGE4 from "../assets/HowCanIrvineHelp_image_4.svg";

function HowCanIrvineHelp_card() {
  const cardsData = [
    {
      image: IMAGE1,
      title: "Cost control of Electricity, Water, Effluent Plant, and Energy",
      description:
        "Are rising operational costs impacting your plant’s efficiency?  Our Real time data collection and analytics platform toolbox is an essential tool for Finance Manager, Plant Manager and Operators to save time and money by utilizing live data of operations to identify and address ineficiencies promptly.",
    },
    {
      image: IMAGE2,
      title: "Enhance Your Process Performance",
      description:
        "Utilize predictive analytics to boost overall process efficiency, improving production rates and product quality by predicting failures of equipment or system through our machine learning-based predictive analysis.",
    },
    {
      image: IMAGE3,
      title: "Regulatory Compliance",
      description:
        "Stay ahead of government regulations with our monitoring tools and receive notifications and alerts of any potential issues ensuring your operations meet all required standards.",
    },
    {
      image: IMAGE4,
      title: "Accessibility, Data Security and Customizability",
      description:
        "Remote, Browser-Based Access: Use our flexible software from any location with internet access and  remote sensor management with AI insights. Customizable Solutions: Tailor the software to meet your unique operational needs.",
    },
  ];

  return (
    <section class="grid xs:grid-cols-1 md:grid-cols-2 gap-y-10 items-stretch mt-5 place-items-center">
      {cardsData.map((card, index) => (
        <div
          key={index}
          class=" bg-white shadow-lg rounded-2xl mb-1 xs:w-[95%] sm:w-[85%] md:w-[95%] lg:w-[85%] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105"
        >
          <div>
            <div class="">
              <img
                src={card.image}
                alt="Images for How Can Irvine Help cards"
                class="object-cover rounded-t-lg  "
              />
            </div>
            <div class="px-10 pb-10 mt-8 text-center text-center">
              <h2 class=" text-xl font-medium  whitespace-normal my-3 ">
                {card.title}
              </h2>
              <p class="xs:text-sm lg:text-sm text-center">
                {card.description}
              </p>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default HowCanIrvineHelp_card;
