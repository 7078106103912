import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

function CaseStudy_Card({ title, description, pdfUrl }) {
  const [showPreview, setShowPreview] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  // Generate thumbnail
  const getThumbnail = async () => {
    try {
      const pdf = await window.pdfjsLib.getDocument(pdfUrl).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 0.9 });
      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const context = canvas.getContext("2d");
      const renderContext = {
        canvasContext: context,
        viewport,
      };
      await page.render(renderContext).promise;
      setThumbnailUrl(canvas.toDataURL());
    } catch (error) {
      console.error("Error generating thumbnail", error);
    }
  };

  useEffect(() => {
    getThumbnail();
  });

  // Handle scrolling behavior when the preview is open or closed
  useEffect(() => {
    if (showPreview) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup when the component is unmounted or showPreview changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPreview]);

  return (
    <div className="flex xs:flex-col md:flex-row justify-between items-center shadow-xl bg-gray rounded-lg p-10 gap-10 ">
      <div className="flex flex-col justify-between gap-4">
        <h3 className="xs:text-xl md:text-2xl font-medium">{title}</h3>
        <h5 className="xs:text-sm lg:text-base">{description}</h5>
        <div className="flex justify-start items-end gap-8 mt-5">
          <button
            onClick={() => setShowPreview(true)}
            className="hover:text-purple font-bold"
          >
            View
          </button>
          <a href={pdfUrl} download className="hover:text-purple font-bold">
            Download
          </a>
        </div>
      </div>
      <div>
        <div className="relative flex xs:justify-center md:justify-end">
          {thumbnailUrl && (
            <img
              src={thumbnailUrl}
              alt="PDF Thumbnail"
              className="xs:w-[70%] sm:w-1/2 md:w-[90%] lg:w-[70%] xl:w-[60%]"
              title="View Case Study"
            />
          )}
        </div>
      </div>
      {showPreview && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex items-center justify-center p-10">
          <div className="relative w-full h-full bg-white shadow-lg p-6">
            <Worker workerUrl={pdfWorker}>
              <Viewer fileUrl={pdfUrl} />
            </Worker>
            <button
              onClick={() => setShowPreview(false)}
              className="absolute top-2 right-8 px-4 py-2 b text-black font-md hover:text-purple"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CaseStudy_Card;
