import React from "react";

import NavBar from "../components/NavBar";
import Hero from "../components/caseStudies/Hero_CaseStudies";
import CaseStudiesList from "../components/caseStudies/CaseStudies_List";
import GetInTouchWithUs from "../components/GetInTouchWithUs";
import Footer from "../components/Footer";
import useScrollToTop from "../hooks/useScrollToTop";

function CaseStudies() {
  useScrollToTop();
  return (
    <div class="">
      <div class="flex flex-col justify-center items-center gap-20">
        <div class=""></div>
        <NavBar />
        <Hero />
        <CaseStudiesList />
      </div>
      <GetInTouchWithUs />

      <Footer />
    </div>
  );
}

export default CaseStudies;
