import Card from "../CaseStudy_Card";
import SAMPLE from "../../assets/saas_image1.svg";

function CaseStudies_List() {
  const caseStudies = [
    {
      title: "Real Time Data Capture and Reporting",
      image: SAMPLE,
      description:
        "Meat Processing Client Solves Monitoring Issues For Effluent Contaminants And Utility Costs Utilising Irvine Analytics Software Platform.",
      pdfURL: "/allCaseStudies/IAS Case Study.14Aug2024.pdf",
    },
  ];

  return (
    <section class="bg-white mb-20">
      <div class="w-[70vw] self-center">
        <div class="flex flex-col justify-center items-center">
          {caseStudies.map((info, index) => (
            <Card
              key={index}
              title={info.title}
              description={info.description}
              image={info.image}
              pdfUrl={info.pdfURL}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default CaseStudies_List;
