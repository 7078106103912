import React, { useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import Modal from "./Modal";

function GetInTouchWithUs_formCard() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalColor, setModalColor] = useState(""); // For setting the color based on success or failure
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:3001/send-email",
        formData
      );
      console.log(response.data);
      // Step 3: Update to use modal for success message
      setModalMessage("Query sent successfully!");
      setModalColor("green"); // Success color
      setIsModalOpen(true);
      setFormData({
        // Reset all fields to initial state, assuming initial state is empty
        // Adjust the keys according to your formData structure
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Failed to send email:", error);
      // Use modal for error message
      setModalMessage("Failed to send Query. Please try again later.");
      setModalColor("red"); // Error color
      setIsModalOpen(true);
    }
  };

  return (
    <div className="xs:w-[80%] md:w-[70%] lg:w-[45%] xl:w-[40%] ">
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <Modal
            isOpen={isModalOpen}
            message={modalMessage}
            onClose={() => setIsModalOpen(false)}
            color={modalColor}
          />
          <div class="text-2xl font-medium">Contact Us</div>
          <div>
            {/* <label htmlFor="name" className="block text-sm font-medium ">
              Name
            </label> */}
            <input
              placeholder="Name"
              type="text"
              id="name"
              name="name"
              className="text-black mt-1 p-2 w-full border border-paleBlue shadow-sm bg-veryLightBlue"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            {/* <label htmlFor="email" className="block text-sm font-medium ">
              Email Address
            </label> */}
            <input
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              className="mt-1 p-2 w-full border border-paleBlue shadow-sm bg-veryLightBlue"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            {/* <label htmlFor="subject" className="block text-sm font-medium ">
              Subject
            </label> */}
            <input
              placeholder="Subject"
              type="text"
              id="subject"
              name="subject"
              className="mt-1 p-2 w-full border border-paleBlue shadow-sm bg-veryLightBlue"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            {/* <label htmlFor="message" className="block text-sm font-medium ">
              Message
            </label> */}
            <textarea
              placeholder="Message"
              id="message"
              name="message"
              rows="8"
              className="mt-1 p-2 w-full border border-paleBlue shadow-sm bg-veryLightBlue"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="flex justify-start">
            <button
              type="submit"
              className="bg-black text-white hover:text-green font-medium text-1xl px-4 py-1 rounded-lg flex items-center justify-center"
            >
              Submit
              <ArrowRightIcon className="ml-2 h-8 w-6 font-bold" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GetInTouchWithUs_formCard;
