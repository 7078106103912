import IMAGE1 from "../assets/sustainability_image1.svg";
import IMAGE2 from "../assets/sustainability_image2.svg";

function Sustainability_Cards() {
  const cardInfo = [
    {
      image: IMAGE1,
      title: "Compliance Verification",
      desc: "Irvine Operator ™ can be used by both corporate management and regulatory authorities to verify compliance with company sustainability and environmental standards and legal requirements for the treatment and disposal of wastewater. The result is to reduce operating costs and the company's local and corporate carbon footprint.",
    },
    {
      image: IMAGE2,
      title: "Resource Optimization",
      desc: "Irvine Operator ™ can be used to optimize the use of resources such as water, energy, chemicals and manpower in the operation of water and wastewater treatment plants. The result is to reduce operating costs and the company's local and corporate carbon footprint.",
    },
  ];

  return (
    <section>
      <div class="flex gap-10 md:flex-row xs:flex-col">
        {cardInfo.map((card, index) => (
          <div
            key={index}
            className="flex flex-col justify-between items-center text-center md:w-[50%] xs:w-[100%] rounded-lg "
          >
            <img src={card.image} alt={card.title} class="w-full rounded-lg " />
            <div class="p-10 flex flex-col h-[100%] gap-5">
              <h3 class="text-3xl font-medium">{card.title}</h3>
              <p class="text-center">{card.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Sustainability_Cards;
