function ContactUs() {
  return (
    <section class="bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center h-[50vh] w-screen flex justify-center items-center">
      <div class="flex flex-col justify-center items-center gap-5">
        <div class="xs:text-5xl lg:text-6xl text-white font-medium text-center">
          Contact Us
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
