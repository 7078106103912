// import ABOUT_US_IMAGE from "../../assets/aboutUs_image.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import SEAL from "../../assets/seal_30years.svg";
import IMAGE1 from "../../assets/aboutUs_image1.svg";
import IMAGE2 from "../../assets/aboutUs_image2.svg";
import IMAGE3 from "../../assets/aboutUs_image3.svg";
import IMAGE4 from "../../assets/aboutUs_image4.svg";
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <section class="flex justify-center items-center">
      <div class="xs:w-[80%] md:w-[85%] lg:w-[90%] xl:w-[80%] flex xs:flex-col md:flex-row justify-between items-center gap-10">
        <div class="md:w-1/2 flex flex-col justify-start items-start gap-5 ">
          <h1 class="xs:text-3xl md:text-4xl lg:text-5xl font-medium ">
            About Us
          </h1>
          <div class="flex flex-col gap-5 xs:text-sm lg:text-base lg:w-[90%] xl:w-full">
            <p>
              It’s a complete tool box for plant and commercial data analysis
              with real-time insights at a low cost.
            </p>
            <p>
              Built by experienced engineers and IT experts, Irvine optimizes
              industrial plant performance.
            </p>
          </div>

          <Link to="/AboutUs">
            <button class="xs:text-sm lg:text-base bg-black text-white hover:text-green font-medium px-5 py-2 rounded-lg flex items-center justify-center mt-3">
              Read More
              <ArrowRightIcon class="h-5 w-5 ml-2" />
            </button>
          </Link>

          <div class="lg:w-3/4 flex flex-row justify-between items-center gap-5 mt-5">
            <img src={SEAL} alt="30 years seal" class="w-1/6" />
            <h3 class="lg:text-lg xl:text-xl font-semibold ">
              <span class="text-purple">30+ YEARS </span>OF COMBINED INDUSTRY
              EXPERIENCE
            </h3>
          </div>
        </div>

        <div class="md:w-1/2 flex flex-row justify-end gap-2 lg:gap-5">
          <div class=" flex flex-col justify-between items-end gap-5 mt-16">
            <img
              src={IMAGE1}
              alt="About Us 1"
              class="rounded-xl md:w-[90%] lg:w-full"
            />
            <img
              src={IMAGE2}
              alt="About Us 2"
              class="rounded-xl md:w-[90%]  lg:w-full"
            />
          </div>
          <div class=" flex flex-col justify-between items-end mb-16">
            <img
              src={IMAGE3}
              alt="About Us 3"
              class="rounded-xl  md:w-[90%] lg:w-full"
            />
            <img
              src={IMAGE4}
              alt="About Us 4"
              class="rounded-xl  md:w-[90%] lg:w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
