import NavBar from "../components/NavBar";
import Hero from "../components/contactUs/Hero_ContactUs";
import GetInTouchWithUs from "../components/contactUs/GetInTouchWithUs_ContactUs";
import Footer from "../components/Footer";
import WhereToFindUs from "../components/contactUs/WhereToFindUs";
import useScrollToTop from "../hooks/useScrollToTop";

function ContactUs() {
  useScrollToTop();
  return (
    <div>
      <div class="flex flex-col gap-20 justify-center items-center">
        <div class="flex flex-col justify-center items-center gap-20">
          <div></div>
          <NavBar />
          <Hero />
        </div>
        <div class="w-full -mt-20">
          <GetInTouchWithUs />
        </div>
        <WhereToFindUs />
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
