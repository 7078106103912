import LOGO_IMAGE from "../assets/ias_logo_navbar.png";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { Link } from "react-router-dom";

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { name: "ABOUT", link: "/AboutUs" },
    { name: "TOOL BOX AND SERVICES", link: "/ProductsAndServices" },
    { name: "CASE STUDIES", link: "/CaseStudies" },
    { name: "OUR TEAM", link: "/OurTeam" },
    { name: "CONTACT US", link: "/ContactUs" },
  ];

  return (
    <nav className="bg-white  fixed w-full top-0 start-0 z-20 bg-white ">
      <div className="flex flex-wrap justify-between mx-10 md:mx-12 xl:mx-20">
        <div>
          <Link to="/">
            <img
              src={LOGO_IMAGE}
              className="h-20"
              alt="Irvine Analytics Solutions Logo"
            ></img>
          </Link>
        </div>

        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="xl:hidden text-black hover:text-purple"
        >
          {isMenuOpen ? (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setIsMenuOpen(false)}
            >
              <XMarkIcon className="h-8 w-8  text-black hover:text-purple" />
            </div>
          ) : (
            <Bars3Icon
              className="h-8 w-8  transition-transform duration-200 ease-in-out text-black hover:text-purple"
              onClick={() => setIsMenuOpen(true)}
            />
          )}
        </button>

        <div
          className={`xl:hidden ${
            isMenuOpen ? "flex" : "hidden"
          } flex-col items-start absolute bg-white left-0 top-0 z-30 mt-20 w-full  bg-white`}
        >
          <ul className="flex flex-col items-start gap-6 px-5 py-2 ml-5 sm:ml-14 md:ml-7 ">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link to={item.link}>
                  <div className="text-black font-medium hover:text-purple ">
                    {item.name}
                  </div>
                </Link>
              </li>
            ))}
          </ul>

          <button className="flex flex-row items-center bg-black font-medium text-white hover:text-green px-5 py-2 rounded-lg m-4 ml-8 sm:ml-16 md:ml-10">
            Get A Demo
            <ArrowRightIcon className="h-5 w-5 ml-2" />
          </button>
        </div>

        <div className=" hidden xl:flex items-center gap-3 ">
          <ul className="flex items-center gap-6 px-5 py-2">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link to={item.link}>
                  <div className="text-black font-medium hover:text-purple ">
                    {item.name}
                  </div>
                </Link>
              </li>
            ))}
          </ul>

          <Link to="/ContactUs">
            <button className=" flex flex-row  justify-center items-center bg-black font-medium text-white hover:text-green px-5 py-2 rounded-lg">
              Get A Demo
              <ArrowRightIcon className="h-5 w-5 ml-2" />
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
