// import HAND_WITH_PHONE from "../../assets/hand_with_phone.png";
import BLACK_TABLET from "../../assets/blackTablet.png";
// import WHITE_TABLET from "../../assets/whiteTablet.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
// import SaasCards from "../SaasCards";
import TextDescIcons from "../TextDescIcons";
import { Link } from "react-router-dom";

function SaasSolution() {
  return (
    <section class="bg-paleBlue">
      <div class="flex xs:flex-col-reverse xs:gap-12 md:gap-0 lg:flex-row justify-between items-center pt-12">
        <img
          src={BLACK_TABLET}
          alt="Hand holding phone with Irvine app on screen"
          class="xs:w-3/4  lg:w-[53%] xl:w-[50%] left-0 bottom-0 lg:self-end xs:self-center"
        />

        <div class="flex flex-col justify-center items-center lg:justify-start lg:items-start gap-6 lg:pl-3 xl:px-6 xl:mr-20 md:pb-12 lg:pb-0">
          <h1 class="xs:text-3xl md:text-4xl font-medium xs:text-center md:text-left text-pretty">
            Software-as-a-Service (SaaS) Solution
          </h1>
          <div class="w-[85%] flex flex-col gap-3 xs:text-sm lg:text-base xs:text-center lg:text-left">
            <p>
              Irvine offers an affordable and easy-to-use way to track your
              plant's performance live.
            </p>
            <p>
              The Irvine Analytics system is a state-of-the-art data collection
              and turn-key package, utilizing the SaaS commercial model and
              avoiding expensive licensing and software costs. Irvine will
              review the client's requirements, then prepare a package including
              site equipment and tailor-made dashboard and analytics.
            </p>
          </div>
          <Link to="/ProductsAndServices">
            <button class="xs:text-sm lg:text-base flex justify-center items-center bg-black text-white hover:text-green font-medium px-5 py-2 rounded-lg">
              Learn More
              <ArrowRightIcon class="h-5 w-5 ml-2" />
            </button>
          </Link>
        </div>
      </div>

      <div class="bg-white pt-20">
        <TextDescIcons />
      </div>
      {/* <div class="bg-white pt-6">
        <SaasCards />
      </div> */}
    </section>
  );
}

export default SaasSolution;
