function Hero_CaseStudies() {
  return (
    <section class="bg-gradient-to-br from-purple from-10% to-lightBlue to-100% bg-center h-[50vh] w-screen flex justify-center items-center">
      <div class="flex flex-col justify-center items-center gap-5">
        <div class="xs:text-5xl lg:text-6xl text-white font-medium text-center">
          Case Studies
        </div>
        {/* <div class="xs:text-base lg:text-lg text-white text-center w-[80%]">
          Bringing Data Analytics Industry, Creating a True, Real-time Digital
          Twin for Operational Efficiency and Cost Savings
        </div> */}
      </div>
    </section>
  );
}

export default Hero_CaseStudies;
