import NavBar from "../components/NavBar";
import Hero from "../components/productsAndServices/Hero_productsAndServices";
import SeeHowItWorks from "../components/SeeHowItWorks";
import GetInTouchWithUs from "../components/GetInTouchWithUs";
import Footer from "../components/Footer";
import OurToolBox from "../components/productsAndServices/OurToolBox";
import Features from "../components/productsAndServices/Features";
import SoftwareOverview from "../components/productsAndServices/SoftwareOverview";
import useScrollToTop from "../hooks/useScrollToTop";

function ProductsAndServices() {
  useScrollToTop();
  return (
    <div class="">
      <div class="flex flex-col justify-center items-center gap-20">
        <div class=""></div>

        <NavBar />
        <Hero />
        <OurToolBox />
        <Features />
        <SoftwareOverview />
        <SeeHowItWorks />
      </div>
      <div class="w-full bg-gradient-to-r from-gray xs:from-[50%] lg:from-[65%] to-white xs:to-[50%] lg:to-[35%]">
        <GetInTouchWithUs />
      </div>
      <Footer />
    </div>
  );
}

export default ProductsAndServices;
