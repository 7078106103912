import React, { useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules"; // Assuming this is the correct import based on your confirmation

import "swiper/css";
import "swiper/css/navigation";

import FOOD_AND_BEVERAGE from "../assets/foodAndBeverage.svg";
import DAIRY from "../assets/dairy.svg";
import MANUFACTURING from "../assets/manufacturing.svg";
import SEMICONDUCTOR from "../assets/semiconductor.svg";
import HEALTHCARE from "../assets/healthcare.svg";
import MEAT_PROCESSING from "../assets/meatProcessing.svg";
import WATER_TREATMENT from "../assets/waterTreatment.svg";
import BREWERIES from "../assets/breweries.svg";
import MINING from "../assets/mining.svg";

const cardsData = [
  { image: FOOD_AND_BEVERAGE, title: "Food & Beverage" },
  { image: DAIRY, title: "Dairy" },
  { image: MANUFACTURING, title: "Manufacturing" },
  { image: SEMICONDUCTOR, title: "Semiconductor" },
  { image: HEALTHCARE, title: "Healthcare" },
  { image: MEAT_PROCESSING, title: "Meat Processing" },
  { image: WATER_TREATMENT, title: "Water Treatment" },
  { image: BREWERIES, title: "Breweries /Brewing" },
  { image: MINING, title: "Mining" },
];

function FoodBeverageManufacturing_carousel() {
  const swiperRef = useRef(null);

  return (
    <div className=" flex justify-between xs:w-[95%] md:w-[90%]  xl:w-[80%] xxl:w-[50%] relative my-swiper-container ">
      <button
        className=" custom-prev "
        onClick={() => swiperRef.current.slidePrev()}
      >
        <ChevronLeftIcon className="h-8 w-8 text-purple" />
      </button>
      <Swiper
        modules={[Navigation]}
        slidesPerView={5} // Default number of slides per view for smaller screens
        breakpoints={{
          // When window width is >= 640px
          300: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 4,
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 5,
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 5,
          },

          1280: {
            slidesPerView: 5,
          },
        }}
        // Add other Swiper properties as needed
        spaceBetween={10}
        loop={true}
        className="multiple-slide-carousel swiper-container w-[90%]"
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {cardsData.map((card, index) => (
          <SwiperSlide key={index}>
            <div
              className="rounded-2xl xs:h-32 sm:h-42  lg:h-48 xl:h-52 bg-cover flex justify-start"
              style={{ backgroundImage: `url(${card.image})` }}
            >
              <span className="xs:text-xs  lg:text-base text-white font-medium xs:p-2 lg:p-3 xs:w-16 md:w-24">
                {card.title}
              </span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Custom Navigation Buttons */}

      <button
        className="custom-next "
        onClick={() => swiperRef.current.slideNext()}
      >
        <ChevronRightIcon className="h-8 w-8 text-purple " />
      </button>
    </div>
  );
}

export default FoodBeverageManufacturing_carousel;
