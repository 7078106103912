import Cards from "../HowCanIrvineHelp_card";
// import SeeHowItWorks from "../SeeHowItWorks";

function HowCanIrvineHelp() {
  return (
    <section class="bg-paleBlue flex justify-center items-center">
      <div class="xs:w-full lg:w-[95%] xl:w-[80%] flex flex-col justify-around items-center p-16">
        <h1 class="xs:text-3xl lg:text-4xl xl:text-5xl font-medium text-black mb-5 ">
          How can Irvine help?
        </h1>
        <p class=" w-[90%] mx-auto flex flex-col justify-center text-center mb-10">
          <span>Take control of your plant with Irvine. </span>
          <span>
            Automate reports, simplify reviews, and optimize performance.
          </span>
        </p>
        <Cards />
      </div>
    </section>
  );
}

export default HowCanIrvineHelp;

// <section class="flex flex-col justify-center items-center">
//   <div class=" flex flex-col justify-center items-center bg-paleBlue p-16">
//     <div class="xs:w-full lg:w-[95%] xl:w-[80%]">
//       <div class="">
//         <div class="text-center mb-16">
//           <h1 class="xs:text-3xl lg:text-4xl xl:text-5xl font-medium text-black mb-5 ">
//             How can Irvine help?
//           </h1>
//           {/* <div class="border-b-2 border-purple w-1/6 mx-auto mb-5"></div> */}
//           <p class=" w-[65%] mx-auto flex flex-col justify-center ">
//             <span>Take control of your plant with Irvine. </span>
//             <span>
//               Automate reports, simplify reviews, and optimize performance.
//             </span>
//           </p>
//         </div>
//         <Cards />
//       </div>
//     </div>
//   </div>

//   <SeeHowItWorks />
// </section>
