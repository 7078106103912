import { ArrowRightIcon } from "@heroicons/react/24/solid";
import LAPTOP from "../../assets/saving_realtime_laptop.svg";
import ICON1 from "../../assets/realTimeAnalytics_icon_1.svg";
import ICON2 from "../../assets/realTimeAnalytics_icon_2.svg";
import ICON3 from "../../assets/realTimeAnalytics_icon_3.svg";
import ICON4 from "../../assets/realTimeAnalytics_icon_4.svg";
import CIRCLE_POINTER from "../RealTimeAnalytics_circularPointer";
import SEMICIRCLE from "../../assets/semicircle.svg";
import { Link } from "react-router-dom";

function RealTimeAnalytics() {
  return (
    <section class="flex flex-col justify-center items-center gap-14">
      <div class="w-[80%] flex flex-col justify-between items-center gap-20 ">
        <h1 class="xs:text-3xl lg:text-4xl xl:text-5xl font-medium text-center">
          Saving Plant Operators Time and Money with Real-Time Analytics
        </h1>
        <div class="grid xs:grid-cols-1 md:grid-cols-2 xs:gap-y-5">
          <img
            src={LAPTOP}
            alt="Laptop with IAS app on screen"
            class="self-center  "
          />

          <div class="relative flex flex-col justify-between items-start xs:gap-5 md:gap-4 lg:gap-10 xl:gap-14 xxl:gap-28 ">
            <img
              src={SEMICIRCLE}
              alt="Laptop with IAS app on screen"
              class="absolute h-[70vh]  hidden lg:block"
            />

            <div class="m:h-full lg:h-[75vh] flex flex-col justify-between gap-8 xs:mt-10 md:mt-0 ">
              <div class="flex gap-12 -mt-8 ">
                <CIRCLE_POINTER imageUrl={ICON1} bgColor="#2d2c8a" />
                <div class="flex flex-col justify-center ">
                  <h1
                    class="lg:text-2xl xl:text-3xl font-medium text-blue"
                    style={{ color: "#2d2c8a" }}
                  >
                    1. Collect
                  </h1>
                  <p>Various Data Collection Options</p>
                </div>
              </div>
              <div class="flex gap-14  lg:pl-14 xl:pl-14">
                <CIRCLE_POINTER imageUrl={ICON2} bgColor="#2a31c3" />
                <div>
                  <h1
                    class="lg:text-2xl xl:text-3xl font-medium text-blue "
                    style={{ color: "#2a31c3" }}
                  >
                    2. Monitor
                  </h1>
                  <p class="">
                    Predict, Compare, Generate, and Monitor Capability
                  </p>
                </div>
              </div>
              <div class="flex gap-14  lg:pl-14 xl:pl-14">
                <CIRCLE_POINTER imageUrl={ICON3} bgColor="#7c1cda" />
                <div>
                  <h1
                    class="lg:text-2xl xl:text-3xl font-medium text-blue"
                    style={{ color: "#7c1cda" }}
                  >
                    3. Analyze
                  </h1>
                  <p>Store Performance Data</p>
                </div>
              </div>
              <div class="flex gap-14">
                <CIRCLE_POINTER imageUrl={ICON4} bgColor="#488aff" />
                <div>
                  <h1
                    class="lg:text-2xl xl:text-3xl font-medium text-blue"
                    style={{ color: "#488aff" }}
                  >
                    4. Predict
                  </h1>
                  <p>Model and Predict Plant Performance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link to="/ProductsAndServices">
        <button class=" bg-black font-medium text-white hover:text-green text-lg px-4 py-1 rounded-lg flex items-center justify-center gap-2 ">
          Learn More
          <ArrowRightIcon class="h-8 w-6 font-bold" />
        </button>
      </Link>
    </section>
  );
}

export default RealTimeAnalytics;
