import DouglasBroughtonImage from "../../assets/douglasBroughton.svg";
import NilesHowsareImage from "../../assets/nilesHowsare.svg";
import JacobTompkinsImage from "../../assets/jacobTompkins.svg";
import MariaKhoImage from "../../assets/mariaKho.svg";
import JoseFaelmanImage from "../../assets/joseFaelman.svg";
import JoshuaPanganibanImage from "../../assets/joshuaPanganiban.svg";
import BrenRomeroImage from "../../assets/brenRomero.svg";

function TeamMember_Cards() {
  const teamMembers = [
    {
      image: DouglasBroughtonImage,
      name: "Douglas Broughton",
      role: "CHIEF ENGINEER",
    },
    {
      image: NilesHowsareImage,
      name: "Niles Howsare",
      role: "CHIEF TECHNOLOGY OFFICER",
    },
    {
      image: JacobTompkinsImage,
      name: "Jacob Tompkins",
      role: "TECHNOLOGY ADVISOR",
    },
    {
      image: MariaKhoImage,
      name: "Maria Kho",
      role: "DEVELOPMENT MANAGER",
    },
    {
      image: JoseFaelmanImage,
      name: "Jose Faelman",
      role: "LEAD SOFTWARE DEVELOPER",
    },
    {
      image: JoshuaPanganibanImage,
      name: "Joshua Panganiban",
      role: "JUNIOR SOFTWARE DEVELOPER",
    },
    {
      image: BrenRomeroImage,
      name: "Bren Romero",
      role: "ADMIN OFFICER",
    },
  ];

  return (
    <section class="xs:w-[80%] md:w-[90%] lg:w-[90%] xl:w-[75%]  grid xs:grid-cols-1 md:grid-cols-2  xs:gap-y-8 md:gap-y-12 md:gap-12 xl:gap-y-16 xl:gap-16 xs:mt-12 md:mt-24 lg:mt-32 mb-16">
      {teamMembers.map((member, index) => (
        <div
          key={index}
          class="flex flex-row items-between xs:shadow-xl md:shadow-2xl gap-4 bg-white rounded-tl-full"
        >
          <img
            src={member.image}
            alt={member.name}
            class="xs:w-1/3 md:w-1/3 lg:w-2/5 xl:w-2/5"
          />
          <div class="flex flex-col justify-center gap-2 pr-4">
            <h3 class=" xs:text-lg sm:text-xl lg:text-2xl font-semibold">
              {member.name}
            </h3>
            <h5 class="text-blue xs:text-xs sm:text-sm md:text-xs lg:text-sm  font-medium">
              {member.role}
            </h5>
          </div>
        </div>
      ))}
    </section>
  );
}

export default TeamMember_Cards;
