import UNDPGoals from "../UNDPGoals";
import SUSTAINABILITY_CARDS from "../Sustainability_Cards";

function Sustainability_AboutUs() {
  return (
    <section class="bg-paleBlue w-full flex flex-col justify-center items-center">
      <div class="xs:w-[80%] md:w-[90%] xl:w-[70%] flex flex-col justify-center items-center my-16">
        <div class="xs:text-4xl lg:text-5xl font-medium text-center mb-10">
          Sustainability and Resilience
        </div>
        {/* <div class="border-b-2 border-purple w-[200px] mx-auto mt-5 mb-10"></div> */}
        <div class="flex flex-col gap-16">
          <UNDPGoals />
          <SUSTAINABILITY_CARDS />
        </div>
      </div>
    </section>
  );
}

export default Sustainability_AboutUs;
