import React, { useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline"; // Import XIcon from Heroicons

const Modal = ({ isOpen, message, onClose, color }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000); // Close the modal after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Determine background color based on the color prop
  const backgroundColorClass = color === "green" ? "bg-green" : "bg-red";

  return (
    <div
      className={`fixed top-0 right-0 mt-4 mr-4 ${backgroundColorClass} flex justify-end items-start rounded z-50`}
    >
      <div className="p-4 rounded-lg shadow-lg z-100">
        <div className="flex justify-between items-center">
          <p className="text-white text-xl">{message}</p>
          <button
            onClick={onClose}
            className="ml-4 bg-transparent text-white font-semibold p-1 rounded " // Added padding and hover effect
          >
            <XMarkIcon className="h-3 w-3" /> {/* Use Heroicon XIcon */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
